<template>
  <div v-if="isStudentEnrolledEmit" :key="student.id" class="btn-group">
    <button class="btn btn-outline-info disabled" disabled>
      {{ student.first }} {{enrolledLabel}}}
    </button>
    <button
      v-if="allowChange"
      :disabled="disabled"
      @click.prevent="dropEnrolled()"
      class="btn btn-danger"
      title="Drop selected time"
    >
      <cs-icon icon="drop" />
    </button>
  </div>
  <button
    v-else-if="inCart"
    :key="student.id"
    v-on:click.prevent="deleteFromCart(student)"
    class="btn btn-warning"
  >
    <font-awesome-icon icon="cart-arrow-down" />
    {{ student.first }}
  </button>
  <button
    v-else-if="course.public && !blockEnrollmentWithUnpaid"
    :key="student.id"
    v-on:click.prevent="addCourseToCart()"
    class="btn btn-success"
  >
    <font-awesome-icon icon="cart-plus" />
    {{ student.first }}
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'
import { isNull, isEmpty } from 'lodash/core'
import moment from 'moment'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'StandardButton',
  mixins: [courseMethods, calendarMethods],
  props: {
    student: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    calendar: {
      type: Object,
      required: false
    },
    day: {
      type: Object,
      required: false
    },
    enrolled: {
      type: Object,
      required: false
    },
    isStudentEnrolled: {
      type: Boolean,
      required: true
    },
    enrolledLabel: {
      type: String,
      required: false,
      default: 'enrolled'
    }

  },
  data () {
    return {
      studentSelections: []
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    addCourseToCart () {
      this.disabled = true
      if (isNull(this.course.session_id)) {
        if (!isNull(this.course.site_calendar_id)) {
          this.addDayToCart(
            this.student,
            this.day.date,
            this.course,
            null,
            null
          )
            .then(data => {
              if (!isEmpty(data.students)) {
                const students = data.students
                for (const studentId in students) {
                  const student = students[studentId]
                  var message = ''
                  if (!isEmpty(student.courses)) {
                    const courses = student.courses
                    var courseNames = []
                    for (const courseId in courses) {
                      const course = courses[courseId]
                      const courseDay = moment(course.day).format('M/D')
                      courseNames.push(course.name + ' ' + courseDay)
                    }
                    message = courseNames.join(',')
                  }
                  this.$notify({
                    group: 'enrollment',
                    type: 'success',
                    title: student.first + ' added to cart',
                    text: message,
                    duration: 2000,
                    speed: 1000
                  })
                }
              }
            })
            .catch(() => {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.student.name + ' problem',
                text:
                  this.student.name +
                  ' problem adding student to cart for day.',
                duration: 2000,
                speed: 1000
              })
            })
        }
      } else {
        this.addToCart(this.student)
          .then(() => {
            // success
          })
          .catch(() => {
            this.disabled = false
          })
      }
    },
    dropEnrolled () {
      var enrolled = this.enrolled_course
      this.disabled = true
      this.drop(enrolled, this.course, this.student)
        .then(function () {})
        .catch(function () {})
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    isStudentEnrolledEmit () {
      if (this.isStudentEnrolled) {
        if (!this.course.public) {
          this.$emit('privateEnrolled')
        }
        return true
      } else {
        return false
      }
    },
    isDropped () {
      const enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && enrolled.dropped) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}
.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
