<!--suppress JSUnfilteredForInLoop -->
<template>
  <span v-if="isEnrolled && !isDropped && !isEditing">
    {{ student.first }} {{ enrolledTime }}
    <button
        :class="{
        disabled: disabled
      }"
        :disabled="disabled"
        class="btn btn-warning btn-truncate-text"
        :title="
        'DROP ' +
          student.first +
          ' from ' +
          enrolledTime +
          '. Dropping marks the enrollment as dropped and shows the history on the registration/account.'
      "
        v-on:click.stop="drop()"
    >
      <font-awesome-icon icon="eraser"/>
      Drop
    </button>
    <button
        :class="{
        disabled: disabled
      }"
        :disabled="disabled"
        class="btn btn-danger btn-truncate-text"
        :title="
        'Delete ' +
          student.first +
          ' from ' +
          enrolledTime +
          '. Deleteing (or unenroll) removes all record of the student being signed up from the registration/account.'
      "
        v-on:click.stop="remove()"
    >
      <font-awesome-icon icon="user-minus"/>
      Delete
    </button>
    <button
        @click.prevent="editing(!isEditing)"
        class="btn btn-primary btn-truncate-text"
        :class="{
        disabled: disabled
      }"
        :disabled="disabled"
        :title="'EDIT ' + student.first + ' from ' + enrolledTime"
    >
      <font-awesome-icon icon="edit"></font-awesome-icon>
    </button>
  </span>
  <span v-else>
    <transition name="fade">
      <div class="alert alert-warning" v-if="isEditing">
        You are currently chosing to change {{ student.first }}'s time of
        {{ enrolledTime }}
      </div>
    </transition>
    <template v-if="usePulldown">
      <template v-if="course.allow_start || course.allow_end">
        <multiselect
            :close-on-select="true"
            :disabled="disabled"
            :options="dropTimes"
            :placeholder="student.first + ' Drop Off'"
            :searchable="false"
            :show-labels="false"
            :value="studentSelection.selectedStart"
            @input="setSelectedStart"
            label="time"
            track-by="time"
            v-if="course.allow_start"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <div class="bg-warning-light p-0">
              <strong> {{ option }}</strong> selected for
              <strong>{{ student.first }}</strong>
            </div>
          </template>
        </multiselect>
        <multiselect
            :close-on-select="true"
            :disabled="disabled"
            :options="pickupTimes"
            :searchable="false"
            :show-labels="false"
            :value="studentSelection.selectedEnd"
            @input="setSelectedEnd"
            label="time"
            track-by="time"
            v-if="course.allow_end"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <div class="bg-warning-light p-0">
              <strong> {{ option }}</strong> selected for
              <strong>{{ student.first }}</strong>
            </div>
          </template>
          <template slot="placeholder"> {{ student.first }} Pick Up </template>
        </multiselect>
        <button
            type="button"
            class="btn btn-warning"
            v-if="isEditing"
            @click.prevent="isEditing = false"
        >
          <font-awesome-icon icon="ban"> Cancel</font-awesome-icon>
        </button>
        <div v-if="!isValid" class="text-danger small">
          You need to select both a drop and a pickup time for
          {{ student.first }}
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="course.allow_start">
        Drop off: {{ student.first }}<br/>
        <button
            :class="{ disabled: disabled }"
            :disabled="disabled"
            :key="'drop' + student.id + time"
            class="btn btn-success btn-sm m-1"
            v-for="time in selectTimes"
            v-on:click.prevent="enroll(time, null)"
        >
          {{ time }}
        </button>
      </template>
      <template v-if="course.allow_end">
        Pick up: {{ student.first }}<br/>
        <button
            :class="{
            disabled: disabled
          }"
            :key="'pickup' + student.id + time"
            class="btn btn-success btn-sm m-1"
            v-for="time in selectTimes"
            v-on:click.prevent="enroll(null, time)"
        >
          {{ time }}
        </button>
      </template>
      <template v-if="!course.allow_end && !course.allow_start">
        {{ course.name }}<br/>
        <button
            :class="{
            disabled: disabled
          }"
            :key="'pickup' + student.id + time"
            class="btn btn-success btn-sm m-1"
            v-for="time in selectTimes"
            v-on:click.prevent="enroll(null, time)"
        >
          {{ time }}
        </button>
      </template>
      <button
          type="button"
          class="btn btn-warning"
          v-if="isEditing"
          @click.prevent="isEditing = false"
      >
        <font-awesome-icon icon="ban"> Cancel</font-awesome-icon>
      </button>
    </template>
  </span>
</template>

<script>
import moment from 'moment'
import { isEmpty, isNull } from 'lodash/core'
import isNil from 'lodash/isNil'
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'ManagerHourlyButton',
  mixins: [courseMethods, calendarMethods],
  props: {
    student: { type: Object, required: true },
    course: { type: Object, required: true },
    day: { type: Object, required: false },
    calendar: { type: Object, required: false },
    enrolled: {
      type: [Array, Object],
      required: false
    }
  },
  data () {
    return {
      studentSelection: [],
      disabled: false,
      lateTime: null,
      isEditing: false
    }
  },
  created () {
    this.program_slug = this.program.slug
    this.studentSelection = {
      name: this.student.name,
      first: this.student.first,
      id: this.student.id,
      selectedStart: null,
      selectedEnd: null
    }
  },
  mounted () {},
  watch: {
    course: function () {
      this.disabled = false
      this.isEditing = false
    },
    inCart: function () {
      this.disabled = false
      this.isEditing = false
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    editing (newValue) {
      if (newValue) {
        this.studentSelection.selectedStart = null
        this.studentSelection.selectedEnd = null
      }
      this.isEditing = newValue
    },
    shouldAddTimes () {
      const course = this.course
      if (course.allow_start && course.allow_end) {
        if (
          !isEmpty(this.studentSelection.selectedStart) &&
            !isEmpty(this.studentSelection.selectedEnd)
        ) {
          this.enroll(
            this.studentSelection.selectedStart,
            this.studentSelection.selectedEnd
          )
          return true
        } else {
          let message = isEmpty(this.studentSelection.selectedStart)
            ? 'Select Pickup Time for '
            : 'Select Drop Off Time for '
          message = message + this.studentSelection.name
          this.$notify({
            group: 'enrollment',
            type: 'warning',
            title: this.course.name,
            text: message,
            duration: 2000,
            speed: 1000
          })
          return false
        }
      } else if (course.allow_start || course.allow_end) {
        this.enroll(
          this.studentSelection.selectedStart,
          this.studentSelection.selectedEnd
        )
        return true
      }
    },
    remove: function () {
      this.disabled = true
      const enrolledCourse = this.enrolled_course
      this.unenrollDay(
        this.student,
        this.registration.member,
        this.day.date,
        this.course,
        enrolledCourse
      )
        .then(() => {
          this.studentSelection.selectedStart = null
          this.studentSelection.selectedEnd = null
        })
        .catch(() => {
          this.disabled = false
        })
    },
    drop: function () {
      this.disabled = true
      const enrolledCourse = this.enrolled_course
      this.dropDay(
        this.student,
        this.registration.member,
        this.day.date,
        this.course,
        enrolledCourse
      )
        .then(() => {
          this.studentSelection.selectedStart = null
          this.studentSelection.selectedEnd = null
        })
        .catch(() => {
          this.disabled = false
        })
    },
    enroll (start, end) {
      this.disabled = true
      if (isNull(this.course.session_id)) {
        this.enrollDay(
          this.student,
          this.registration.member,
          this.day.date,
          this.course,
          start,
          end
        )
      } else {
        this.enrollTime(this.student, start, end)
          .then(() => {
            // this.disabled = false;
          })
          .catch(() => {
            this.disabled = false
          })
      }
    },
    // sets the start time and can enroll/add to cart the student
    selectedStart () {
      return this.studentSelection.startSelection
    },
    setSelectedStart (selected) {
      this.studentSelection.selectedStart = selected.time
      this.shouldAddTimes()
    },
    setSelectedEnd (selected) {
      this.studentSelection.selectedEnd = selected.time
      this.shouldAddTimes()
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'isProgramManager'
    }),
    allowChange () {
      let registrationOpen = false
      if (!isNil(this.session.registrationOpen)) {
        registrationOpen = this.session.registrationOpen
      } else if (!isNil(this.calendar.registration_open)) {
        registrationOpen = this.calendar.registration_open
      }
      if (!this.signupClosed && this.course.allow_change && registrationOpen) {
        return true
      } else {
        return false
      }
    },
    isValid () {
      if (this.course.allow_start && this.course.allow_end) {
        if (
          (isEmpty(this.studentSelection.selectedStart) &&
                !isEmpty(this.studentSelection.selectedEnd)) ||
            (!isEmpty(this.studentSelection.selectedStart) &&
                isEmpty(this.studentSelection.selectedEnd))
        ) {
          return false
        }
      }
      return true
    },
    lateTimeConfig () {
      return {
        wrap: true,
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        minDate: this.lateTimeStart,
        maxDate: this.lateTimeEnd,
        dateFormat: 'h:i K',
        minuteIncrement: 1
      }
    },
    lateTimeStart () {
      return moment()
        .minute(0)
        .hour(18)
        .seconds(0)
        .toDate()
    },
    lateTimeEnd () {
      return moment()
        .minute(0)
        .hour(18 + 1)
        .seconds(0)
        .toDate()
    },
    usePulldown: function () {
      return this.course.use_pulldown
    },
    enrolledTime () {
      var enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && !enrolled.dropped) {
        const format = 'h:mma'
        if (!isEmpty(enrolled.start) && !isEmpty(enrolled.end)) {
          return (
            moment(enrolled.start).format(format) +
              '-' +
              moment(enrolled.end).format(format)
          )
        } else if (!isEmpty(enrolled.start)) {
          return moment(enrolled.start).format(format)
        } else if (!isEmpty(enrolled.end)) {
          return moment(enrolled.end).format(format)
        }
      }
      return ''
    },

    studentCanEnroll: function () {
      const grades = this.course.grade_keys
      if (grades.indexOf(this.student.grade) >= 0) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
