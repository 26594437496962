var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isEnrolled && !_vm.isDropped && !_vm.isEditing)?_c('span',[_vm._v("\n  "+_vm._s(_vm.student.first)+" "+_vm._s(_vm.enrolledTime)+"\n  "),_c('button',{staticClass:"btn btn-warning btn-truncate-text",class:{
      disabled: _vm.disabled
    },attrs:{"disabled":_vm.disabled,"title":'DROP ' +
        _vm.student.first +
        ' from ' +
        _vm.enrolledTime +
        '. Dropping marks the enrollment as dropped and shows the history on the registration/account.'},on:{"click":function($event){$event.stopPropagation();return _vm.drop()}}},[_c('font-awesome-icon',{attrs:{"icon":"eraser"}}),_vm._v("\n    Drop\n  ")],1),_vm._v(" "),_c('button',{staticClass:"btn btn-danger btn-truncate-text",class:{
      disabled: _vm.disabled
    },attrs:{"disabled":_vm.disabled,"title":'Delete ' +
        _vm.student.first +
        ' from ' +
        _vm.enrolledTime +
        '. Deleteing (or unenroll) removes all record of the student being signed up from the registration/account.'},on:{"click":function($event){$event.stopPropagation();return _vm.remove()}}},[_c('font-awesome-icon',{attrs:{"icon":"user-minus"}}),_vm._v("\n    Delete\n  ")],1),_vm._v(" "),_c('button',{staticClass:"btn btn-primary btn-truncate-text",class:{
      disabled: _vm.disabled
    },attrs:{"disabled":_vm.disabled,"title":'EDIT ' + _vm.student.first + ' from ' + _vm.enrolledTime},on:{"click":function($event){$event.preventDefault();return _vm.editing(!_vm.isEditing)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1)]):_c('span',[_c('transition',{attrs:{"name":"fade"}},[(_vm.isEditing)?_c('div',{staticClass:"alert alert-warning"},[_vm._v("\n      You are currently chosing to change "+_vm._s(_vm.student.first)+"'s time of\n      "+_vm._s(_vm.enrolledTime)+"\n    ")]):_vm._e()]),_vm._v(" "),(_vm.usePulldown)?[(_vm.course.allow_start || _vm.course.allow_end)?[(_vm.course.allow_start)?_c('multiselect',{attrs:{"close-on-select":true,"disabled":_vm.disabled,"options":_vm.dropTimes,"placeholder":_vm.student.first + ' Drop Off',"searchable":false,"show-labels":false,"value":_vm.studentSelection.selectedStart,"label":"time","track-by":"time"},on:{"input":_vm.setSelectedStart},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
    var option = ref.option;
return [_c('div',{staticClass:"bg-warning-light p-0"},[_c('strong',[_vm._v(" "+_vm._s(option))]),_vm._v(" selected for\n            "),_c('strong',[_vm._v(_vm._s(_vm.student.first))])])]}}],null,false,3201386347)}):_vm._e(),_vm._v(" "),(_vm.course.allow_end)?_c('multiselect',{attrs:{"close-on-select":true,"disabled":_vm.disabled,"options":_vm.pickupTimes,"searchable":false,"show-labels":false,"value":_vm.studentSelection.selectedEnd,"label":"time","track-by":"time"},on:{"input":_vm.setSelectedEnd},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
    var option = ref.option;
return [_c('div',{staticClass:"bg-warning-light p-0"},[_c('strong',[_vm._v(" "+_vm._s(option))]),_vm._v(" selected for\n            "),_c('strong',[_vm._v(_vm._s(_vm.student.first))])])]}}],null,false,3201386347)},[_vm._v(" "),_c('template',{slot:"placeholder"},[_vm._v(" "+_vm._s(_vm.student.first)+" Pick Up ")])],2):_vm._e(),_vm._v(" "),(_vm.isEditing)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.isEditing = false}}},[_c('font-awesome-icon',{attrs:{"icon":"ban"}},[_vm._v(" Cancel")])],1):_vm._e(),_vm._v(" "),(!_vm.isValid)?_c('div',{staticClass:"text-danger small"},[_vm._v("\n        You need to select both a drop and a pickup time for\n        "+_vm._s(_vm.student.first)+"\n      ")]):_vm._e()]:_vm._e()]:[(_vm.course.allow_start)?[_vm._v("\n      Drop off: "+_vm._s(_vm.student.first)),_c('br'),_vm._v(" "),_vm._l((_vm.selectTimes),function(time){return _c('button',{key:'drop' + _vm.student.id + time,staticClass:"btn btn-success btn-sm m-1",class:{ disabled: _vm.disabled },attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.enroll(time, null)}}},[_vm._v("\n        "+_vm._s(time)+"\n      ")])})]:_vm._e(),_vm._v(" "),(_vm.course.allow_end)?[_vm._v("\n      Pick up: "+_vm._s(_vm.student.first)),_c('br'),_vm._v(" "),_vm._l((_vm.selectTimes),function(time){return _c('button',{key:'pickup' + _vm.student.id + time,staticClass:"btn btn-success btn-sm m-1",class:{
          disabled: _vm.disabled
        },on:{"click":function($event){$event.preventDefault();return _vm.enroll(null, time)}}},[_vm._v("\n        "+_vm._s(time)+"\n      ")])})]:_vm._e(),_vm._v(" "),(!_vm.course.allow_end && !_vm.course.allow_start)?[_vm._v("\n      "+_vm._s(_vm.course.name)),_c('br'),_vm._v(" "),_vm._l((_vm.selectTimes),function(time){return _c('button',{key:'pickup' + _vm.student.id + time,staticClass:"btn btn-success btn-sm m-1",class:{
          disabled: _vm.disabled
        },on:{"click":function($event){$event.preventDefault();return _vm.enroll(null, time)}}},[_vm._v("\n        "+_vm._s(time)+"\n      ")])})]:_vm._e(),_vm._v(" "),(_vm.isEditing)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.isEditing = false}}},[_c('font-awesome-icon',{attrs:{"icon":"ban"}},[_vm._v(" Cancel")])],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }