var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-group list-group-flush",class:'bg-' + _vm.program.slug + '-light'},[(!_vm.course.allow_start && !_vm.course.allow_end)?_c('span',{staticClass:"w-100 text-center"},[_c('strong',{staticClass:"px-2",domProps:{"innerHTML":_vm._s(_vm.course.name)}})]):_vm._e(),_vm._v(" "),(!_vm.isManager)?_vm._l((_vm.studentSelections),function(student){return _c('div',{key:student.id,staticClass:"list-group-item select-time p-2"},[(_vm.signupClosed)?[_vm._v("\n        "+_vm._s(_vm.signupClosedReason)+"\n      ")]:_vm._e(),_vm._v(" "),(
          (_vm.templateContained(student) ||
            _vm.enrolledCourseStudent(_vm.course, student)) &&
            !_vm.allowChange
        )?[_c('span',{staticClass:"text-muted small"},[_vm._v(_vm._s(student.first)+" enrolled")])]:(!_vm.course.allow_start && !_vm.course.allow_end)?_c('app-standard-button',{key:'add-cart-' + _vm.course.id + '-student-' + student.id,attrs:{"course":_vm.course,"student":student,"day":_vm.day,"enrolled":_vm.enrolled,"calendar":_vm.calendar,"is-student-enrolled":_vm.templateContained(student) || _vm.enrolledCourseStudent(_vm.course, student)}}):_c('app-hourly-button',{attrs:{"course":_vm.course,"day":_vm.day,"student":student,"calendar":_vm.calendar,"enrolled":_vm.enrolled}})],2)}):_vm._l((_vm.studentSelections),function(student){return _c('div',{key:'enroll' + student.id,staticClass:"list-group-item select-time"},[(
          (_vm.templateContained(student) ||
            _vm.enrolledCourseStudent(_vm.course, student)) &&
            !_vm.allowChange
        )?[_c('span',{staticClass:"text-muted small"},[_vm._v(_vm._s(student.first)+" enrolled")])]:(!_vm.course.allow_start && !_vm.course.allow_end)?_c('app-manager-standard-button',{key:'manager-enroll-' + _vm.course.id + '-student-' + student.id,attrs:{"course":_vm.course,"student":student,"day":_vm.day,"enrolled":_vm.enrolled,"calendar":_vm.calendar,"is-student-enrolled":_vm.templateContained(student) || _vm.enrolledCourseStudent(_vm.course, student)}}):_c('app-manager-hourly-button',{attrs:{"course":_vm.course,"day":_vm.day,"student":student,"calendar":_vm.calendar,"enrolled":_vm.enrolled}})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }