<template>
  <div>
    <div
      v-if="
        course.hourly_fee === 0 || (!course.allow_start && !course.allow_end)
      "
    >
      <app-standard-buttons
        :course="course"
        :calendar="calendar"
        :day="day"
        :students="students"
        :enrolled="enrolled"
        :overlap_courses="overlap_courses"
        :enrolled-label="enrolledLabel"
      />
    </div>
    <div v-else>
      <app-hourly-buttons
        :calendar="calendar"
        :course="course"
        :day="day"
        :enrolled="enrolled"
        :overlap_courses="overlap_courses"
        :students="students"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import HourlyButtons from '@/components/shared/HourlyButtons'
import StandardButtons from '@/components/shared/StandardButtons'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'EnrollButtons',
  mixins: [courseMethods],
  props: {
    course: { type: Object, required: true },
    day: { type: Object, required: false },
    calendar: { type: Object, required: false },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    overlap_courses: {
      type: Object,
      required: false
    },
    enrolledLabel: {
      type: String,
      required: false,
      default: 'enrolled'
    }
  },
  data () {
    return {}
  },
  components: {
    appHourlyButtons: HourlyButtons,
    appStandardButtons: StandardButtons
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  methods: {
    // @vuese
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    })
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager'
    })
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
