import { mapActions, mapGetters, mapMutations } from 'vuex'
import { UPDATE_ENROLLED_COURSES, UPDATE_ENROLLMENT, UPDATE_SESSION, UPDATE_WAITLISTS } from '@/constants/mutation-types'
import { API, MANAGER_API } from '@/utilities/http-common'
import moment from 'moment-timezone'
import { isEmpty, isNull } from 'lodash/core'
import isNil from 'lodash/isNil'
import { addStudentCourseToWaitlist, removeStudentCourseFromWaitlist } from '@/api/courseAPI'

moment.tz.setDefault('America/Phoenix')
export const courseMethods = {
  computed: {
    ...mapGetters({
      member: 'registrationMember',
      registration: 'registration',
      enrolledCourses: 'enrolledCourses',
      program: 'program'
    }),
    allowChange () {
      let registrationOpen = false
      const course = this.course
      if (
        !isEmpty(this.session) &&
                !isNil(this.session.registrationOpen) &&
                !isEmpty(course.session_id)
      ) {
        registrationOpen = this.session.registrationOpen
      } else if (
        !isEmpty(this.calendar) &&
                !isNil(this.calendar.scheduling_open) &&
                !isEmpty(course.site_calendar_id)
      ) {
        registrationOpen = this.calendar.scheduling_open
      }
      if (!this.signupClosed && this.course.allow_change && registrationOpen) {
        return true
      } else {
        return false
      }
    },
    isSession: function () {
      return !this.isCalendar
    },
    enrolled_course () {
      const course = this.course
      const student = this.student
      return this.enrolledCourseStudent(course, student)
    },
    isEnrolled () {
      const enrolled = this.enrolled_course
      return !isEmpty(enrolled) && !enrolled.dropped
    },
    isDropped () {
      const enrolled = this.enrolled_course
      return !!(!isEmpty(enrolled) && enrolled.dropped)
    },
    cart () {
      if (this.bulk) {
        return null
      }
      return this.cartForStudentInCourse(this.student, this.course)
      if (
        this.course.session_id > 0 &&
                !isEmpty(this.enrolledCourses) &&
                !isEmpty(this.enrolledCourses.sessions)
      ) {
        const sessions = this.enrolledCourses.sessions
        if (typeof sessions[this.course.session_id] !== 'undefined') {
          const session = sessions[this.course.session_id]
          if (typeof session[this.student.id] !== 'undefined') {
            const enrolledStudent = session[this.student.id]
            if (typeof enrolledStudent[this.course.id] !== 'undefined') {
              return enrolledStudent[this.course.id]
            }
          }
        }
      } else if (
        !isEmpty(this.course.calendar_id) &&
                !isEmpty(this.enrolledCourses) &&
                !isEmpty(this.enrolledCourses.calendars)
      ) {
        const calendars = this.enrolledCourses.calendars
        const date = moment(this.day.date)
        if (typeof calendars[this.course.calendar_id] !== 'undefined') {
          const calendar = calendars[this.course.calendar_id]
          if (typeof calendar[this.student.id] !== 'undefined') {
            const enrolledStudent = calendar[this.student.id]
            for (const courseId in enrolledStudent) {
              // noinspection JSUnfilteredForInLoop
              const course = enrolledStudent[courseId]
              if (
                parseInt(course.template_id) === parseInt(this.course.id) &&
                                date.isSame(course.date, 'day')
              ) {
                return course
              }
            }
          }
        }
      }
      return null
    },
    inCart: function () {
      const cart = this.cart
      return !isNull(cart)
    },
    dropTimes () {
      var selectTimes = this.selectTimes.map(time => {
        return {
          time: time,
          student: this.student
        }
      })
      if (this.student.selectedEnd) {
        const selectedEnd = this.studentSelection.selectedEnd
        const index = selectTimes.findIndex(function (time) {
          return selectedEnd === time.time
        })
        return selectTimes.slice(0, index)
      }
      return selectTimes
    },
    pickupTimes () {
      var selectTimes = this.selectTimes.map(time => {
        return {
          time: time,
          student: this.student
        }
      })
      if (this.studentSelection.selectedStart) {
        const selectedEnd = this.studentSelection.selectedStart
        const index = selectTimes.findIndex(function (time) {
          return selectedEnd === time.time
        })
        return selectTimes.slice(index + 1)
      }
      return selectTimes
    },
    overlapCourses () {
      if (
        this.calendar.credit_overlap && !isEmpty(this.enrolled) &&
                typeof this.enrolled[this.student.id] !== 'undefined'
      ) {
        const courses = this.enrolled[this.student.id]
        return courses
      }
      return []
    },
    overlapStart () {
      const courses = this.overlapCourses
      let start = moment(this.course.endTime)
      if (typeof courses !== 'undefined' && courses.length > 0) {
        courses.forEach(function (course) {
          const courseStart = moment(course.startTime)
          if (courseStart.isBefore(start, 'minute')) {
            start = courseStart
          }
        })
      }
      return start
    },
    overlapEnd () {
      const courses = this.overlapCourses
      let end = moment(this.course.startTime)
      if (typeof courses !== 'undefined' && courses.length > 0) {
        courses.forEach(function (course) {
          const courseEnd = moment(course.endTime)
          if (courseEnd.isAfter(end, 'minute')) {
            end = courseEnd
          }
        })
      }
      return end
    },
    selectTimes: function () {
      let startTime = this.course.startTime
      let endTime = this.course.endTime
      if (
        this.calendar.credit_overlap && !isEmpty(this.enrolled) &&
                typeof this.enrolled[this.student.id] !== 'undefined'
      ) {
        const enrolledStart = this.overlapStart
        const enrolledEnd = this.overlapEnd
        if (
          enrolledStart.isBefore(endTime) &&
                    enrolledStart.isAfter(startTime)
        ) {
          endTime = enrolledStart
        }
        if (enrolledEnd.isAfter(startTime) && enrolledEnd.isBefore(endTime)) {
          startTime = enrolledEnd
        }
      }
      const course = this.course
      var selectIncrement = course.select_increment
      if (typeof selectIncrement === 'undefined') {
        selectIncrement = 60
      }
      var times = this.getTimeStops(
        startTime,
        endTime,
        selectIncrement,
        this.course.increment_from_front
      )
      console.log('selectTimes before', course.name, times)
      if (this.course.allow_end && !this.course.allow_start) {
        times.shift()
      } else if (!this.course.allow_end && this.course.allow_start) {
        times.pop()
      }
      console.log('selectTimes after', course.name, times)
      return times
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES,
      updateSession: UPDATE_SESSION,
      updateEnrollment: UPDATE_ENROLLMENT
    }),
    ...mapMutations({
      updateWaitlists: UPDATE_WAITLISTS
    }),
    isStudentInCartForCourse: function (student, course) {
      const cart = this.cartForStudentInCourse(student, course)
      return !isNull(cart)
    },
    cartForStudentInCourse (student, course) {
      if (isEmpty(student)) {
        return null
      }
      if (
        course.session_id > 0 &&
                !isEmpty(this.enrolledCourses) &&
                !isEmpty(this.enrolledCourses.sessions)
      ) {
        const sessions = this.enrolledCourses.sessions
        if (typeof sessions[course.session_id] !== 'undefined') {
          const session = sessions[course.session_id]
          if (typeof session[student.id] !== 'undefined') {
            const enrolledStudent = session[student.id]
            if (typeof enrolledStudent[course.id] !== 'undefined') {
              return enrolledStudent[course.id]
            }
          }
        }
      } else if (
        !isEmpty(course.calendar_id) &&
                !isEmpty(this.enrolledCourses) &&
                !isEmpty(this.enrolledCourses.calendars)
      ) {
        const calendars = this.enrolledCourses.calendars
        const date = moment(this.day.date)
        if (typeof calendars[course.calendar_id] !== 'undefined') {
          const calendar = calendars[course.calendar_id]
          if (typeof calendar[this.student.id] !== 'undefined') {
            const enrolledStudent = calendar[this.student.id]
            for (const courseId in enrolledStudent) {
              // noinspection JSUnfilteredForInLoop
              const enrolledCourse = enrolledStudent[courseId]
              if (
                parseInt(enrolledCourse.template_id) === parseInt(course.id) &&
                                date.isSame(enrolledCourse.date, 'day')
              ) {
                return enrolledCourse
              }
            }
          }
        }
      }
      return null
    },
    memberGte (role) {
      if (typeof this.member !== 'undefined') {
        if (role === 'admin' && this.member.role === 'admin') {
          return true
        } else if (
          role === 'program' &&
                    (this.member.role === 'program' || this.member.role === 'admin')
        ) {
          return true
        } else if (
          role === 'site' &&
                    (this.member.role === 'program' ||
                        this.member.role === 'admin' ||
                        this.member.role === 'site')
        ) {
          return true
        } else if (
          role === 'staff' &&
                    (this.member.role === 'program' ||
                        this.member.role === 'admin' ||
                        this.member.role === 'site' ||
                        this.member.role === 'staff')
        ) {
          return true
        } else if (
          role === 'parent' &&
                    (this.member.role === 'program' ||
                        this.member.role === 'admin' ||
                        this.member.role === 'site' ||
                        this.member.role === 'staff' ||
                        this.member.role === 'parent')
        ) {
          return true
        } else if (
          role === 'public' &&
                    (this.member.role === 'program' ||
                        this.member.role === 'admin' ||
                        this.member.role === 'site' ||
                        this.member.role === 'staff' ||
                        this.member.role === 'parent' ||
                        this.member.role === 'public')
        ) {
          return true
        }
      }
      return false
    },
    getTimeStops (start, end, increment, incrementFromFront) {
      if (typeof incrementFromFront === 'undefined') {
        incrementFromFront = true
      }
      const startTime = moment(start)
      const endTime = moment(end)
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day')
      }
      var timeStops = []
      if (incrementFromFront) {
        let lastAdded = moment(startTime)
        // eslint-disable-next-line no-unmodified-loop-condition
        while (startTime <= endTime) {
          // eslint-disable-next-line new-cap
          timeStops.push(new moment(startTime).format('h:mma'))
          lastAdded = moment(startTime)
          startTime.add(increment, 'minutes')
        }
        if (!lastAdded.isSame(endTime, 'minutes')) {
          // eslint-disable-next-line new-cap
          timeStops.push(new moment(endTime).format('h:mma'))
        }
      } else {
        let lastAdded = moment(endTime)
        // eslint-disable-next-line no-unmodified-loop-condition
        while (startTime <= endTime) {
          // eslint-disable-next-line new-cap
          timeStops.unshift(new moment(endTime).format('h:mma'))
          lastAdded = moment(endTime)
          endTime.subtract(increment, 'minutes')
        }
        if (!lastAdded.isSame(startTime, 'minutes')) {
          // eslint-disable-next-line new-cap
          timeStops.unshift(new moment(startTime).format('h:mma'))
        }
      }
      return timeStops
    },
    hasStudentsWhoCanEnrollInCourse: function (course, students) {
      const result = this.studentsWhoCanEnrollInCourse(course, students)
      if (typeof result === 'undefined' || result.length === 0) {
        return false
      }
      return true
    },
    /**
         *
         * @param course
         * @param students
         * @returns {*[]|*}
         */
    studentsWhoCanEnrollInCourse: function (course, students) {
      const calendar = this.calendar

      function isInGrade (value) {
        if (typeof value.grade === 'undefined') {
          return false
        }
        if (!isEmpty(calendar) && calendar.use_next_year) {
          return this.includes(value.next_grade)
        }
        return this.includes(value.grade)
      }

      if (typeof this.students !== 'undefined') {
        let filteredStudents = students.filter(isInGrade, course.grade_keys)
        if (!isEmpty(calendar) && calendar.only_in_course) {
          filteredStudents = filteredStudents.filter(student => {
            if (typeof this.enrolled === 'undefined') {
              return false
            }
            const enrolled = this.enrolled[student.id]
            if (typeof enrolled === 'undefined') {
              return false
            } else {
              return this.courseOverlapped(student)
            }
          })
        }
        return filteredStudents
      } else {
        return []
      }
    },
    addTimeToCart (student, start, end) {
      const enrolledCourse = {
        course_id: this.course.id,
        student_id: student.id,
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        member_id: this.member.id,
        start: start,
        end: end
      }
      const url = 'programs/' + this.program.slug + '/enrolled_courses/add'
      return new Promise((resolve, reject) => {
        API.put(url, enrolledCourse)
          .then(response => {
            const data = response.data
            if (data.result.isError === false) {
              const enrolledCourses = data.enrolled_courses
              this.updateEnrolledCourses(enrolledCourses)
              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.$emit('added-to-cart', {
                student: this.student,
                course: this.course,
                start: start,
                end: end
              })
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    addToCart (student, event) {
      if (typeof event !== 'undefined') {
        event.target.disabled = true
      }
      const enrolledCourse = {
        course_id: this.course.id,
        student_id: student.id,
        registration_id: this.registration.id,
        member_id: this.member.id
      }
      const url = 'programs/' + this.program.slug + '/enrolled_courses/add'
      return new Promise((resolve, reject) => {
        API.put(url, enrolledCourse)
          .then(response => {
            const data = response.data
            const enrolledCourses = data.enrolled_courses
            const result = data.result
            if (typeof data !== 'undefined') {
              if (
                typeof result.isError !== 'undefined' &&
                                result.isError === false
              ) {
                this.updateEnrolledCourses(enrolledCourses)
                if (typeof event !== 'undefined') {
                  event.target.disabled = false
                }
                this.$emit('added-to-cart', { student: this.student, course: this.course })
                this.$notify({
                  group: 'enrollment',
                  type: 'success',
                  title: this.course.name,
                  text: result.message,
                  duration: 2000,
                  speed: 1000
                })
                resolve(response)
              } else if (data.isError === true) {
                if (typeof event !== 'undefined') {
                  event.target.disabled = false
                }
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: this.course.name + ' could not be added to cart',
                  text: result.message,
                  duration: 2000,
                  speed: 1000
                })
                reject(response)
              }
            }
          })
          .catch(e => {
            if (typeof event !== 'undefined') {
              event.target.disabled = false
            }
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name + ' could not be added to cart',
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    removeCourseFromCart (student, course, session, slug, registration, event) {
      if (typeof event !== 'undefined') {
        event.target.disabled = true
      }
      const enrolledCourse = {
        course_id: course.id,
        student_id: student.id,
        session_id: session.id,
        program_slug: slug,
        registration_id: registration.id
      }
      const url = 'programs/' + this.program.slug + '/enrolled_courses/delete'
      return new Promise((resolve, reject) => {
        API.delete(url, { params: enrolledCourse })
          .then(response => {
            const data = response.data
            const enrolledCourses = data.enrolled_courses
            const result = data.result
            this.updateEnrolledCourses(enrolledCourses)
            if (typeof event !== 'undefined') {
              event.target.disabled = false
            }
            this.$notify({
              group: 'enrollment',
              type: 'info',
              title: course.name,
              text: result.message,
              duration: 2000,
              speed: 1000
            })
            resolve(response)
          })
          .catch((e) => {
            if (typeof event !== 'undefined') {
              event.target.disabled = false
            }
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: course.name,
              text: 'Could not delete ' + course.name + ' from cart!',
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    deleteFromCart (student, event) {
      if (typeof event !== 'undefined') {
        event.target.disabled = true
      }
      this.removeCourseFromCart(
        student,
        this.course,
        this.session,
        this.program.slug,
        this.registration,
        event
      )
    },
    addToWaitlist (student, event) {
      this.$wait.start('waitlist.add.' + this.course.id + '.' + student.id)
      const waitlist = {
        course_id: this.course.id,
        type: this.course.waitlist,
        student_id: student.id,
        registration_id: this.registration.id,
        member_id: this.member.id
      }
      addStudentCourseToWaitlist(student, this.course, this.member.id, this.registration.id, this.program.slug)
        .then(response => {
          this.$wait.end('waitlist.add.' + this.course.id + '.' + student.id)
          if (response.status === 200) {
            const data = response.data
            this.updateEnrolledCourses(data.enrolled_courses)
            this.updateWaitlists(data.waitlists)
            try {
              this.addedToWaitlist[student.id] = true
            } catch (e) {
              console.error('courseMixin.addToWaitlist', e)
            }
            this.$notify({
              group: 'enrollment',
              type: 'success',
              title: this.course.name,
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
          } else {
            const data = response.data
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
          }
        })
        .catch(error => {
          this.$wait.end('waitlist.add.' + this.course.id + '.' + student.id)
          return alert(error.message)
        })
    },
    removeFromWaitlist (waitlistRecord, event) {
      this.$wait.start('waitlist.remove.' + waitlistRecord.course_id + '.' + waitlistRecord.student_id)
      removeStudentCourseFromWaitlist(waitlistRecord, this.program.slug)
        .then(response => {
          this.$wait.end('waitlist.remove.' + waitlistRecord.course_id + '.' + waitlistRecord.student_id)
          const data = response.data
          if (typeof data !== 'undefined') {
            const result = data.result
            if (typeof result !== 'undefined' &&
                            typeof result.isError !== 'undefined' &&
                            result.isError === false
            ) {
              this.updateEnrolledCourses(data.enrolled_courses)
              this.updateWaitlists(data.waitlists)
              this.$notify({
                group: 'enrollment',
                type: 'info',
                title: this.course.name,
                text: data.message,
                duration: 2000,
                speed: 1000
              })
              try {
                this.addedToWaitlist[waitlistRecord.student_id] = false
              } catch (e) {
                console.error('courseMixin.removeFromWaitlist', e)
              }
            } else if (data.isError === true) {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
            }
          }
        })
        .catch(error => {
          this.$wait.end('waitlist.remove.' + waitlistRecord.course_id + '.' + waitlistRecord.student_id)
          alert(error.message)
        })
    },
    isStudentEnrolledInCourse (course, student) {
      const enrollment = this.enrolledCourseStudent(
        this.course,
        student
      )
      return !isEmpty(enrollment)
    },
    enrolledCourseStudent (course, student) {
      if (
        typeof course.students !== 'undefined' &&
                course.students.length > 0
      ) {
        const inCourse = course.students.find(function (checkStudent) {
          return checkStudent.id === student.id
        })
        if (typeof inCourse !== 'undefined') {
          return inCourse.enrolled_course
        }
      }
      if (!isEmpty(course.calendar_id) && !isEmpty(this.calendar.courses)) {
        const templateId = course.id
        const day = this.day.date
        const courseForTemplate = this.calendar.courses.find(function (course) {
          const startDate = moment(course.startDate)
          return (
            parseInt(course.template_id) === parseInt(templateId) &&
                        startDate.isSame(day, 'day')
          )
        })
        if (!isEmpty(courseForTemplate)) {
          const enrolledCourse = courseForTemplate.students.find(function (
            templateStudent
          ) {
            return (
              parseInt(student.id) === parseInt(templateStudent.student_id)
            )
          })
          if (!isEmpty(enrolledCourse)) {
            return enrolledCourse
          }
        }
      }
      return null
    },
    enrollTime: function (student, start, end) {
      const enrolledCourse = {
        course_id: this.course.id,
        student_id: student.id,
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        member_id: this.registration.member_id,
        start: start,
        end: end
      }
      const url = '/enrolled_courses/enroll'
      return new Promise((resolve, reject) => {
        MANAGER_API.put(url, enrolledCourse)
          .then(response => {
            const data = response.data
            this.updateEnrollment()
            if (data.result.isError === false) {
              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.$emit('enrolled-course', {
                student: student,
                course: this.course,
                start: start,
                end: end
              })
              this.processed = true
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    enrollCourseWithBalance: function (student) {
      const enrolledCourse = {
        course_id: this.course.id,
        student_id: student.id,
        registration_id: this.registration.id,
        member_id: this.registration.member_id
      }
      const url = '/enrolled_courses/enroll_with_balance'
      return new Promise((resolve, reject) => {
        API.put(url, enrolledCourse)
          .then(response => {
            const data = response.data
            this.updateEnrollment()
            if (data.result.isError === false) {
              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.$emit('enrolled-course', { student: student, course: this.course })
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    enroll: function (student) {
      const enrolledCourse = {
        course_id: this.course.id,
        student_id: student.id,
        registration_id: this.registration.id,
        member_id: this.registration.member_id
      }
      const url = '/enrolled_courses/enroll'
      return new Promise((resolve, reject) => {
        MANAGER_API.put(url, enrolledCourse)
          .then(response => {
            const data = response.data
            this.updateEnrollment()
            if (data.result.isError === false) {
              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.$emit('enrolled-course', { student: student, course: this.course })
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    unenroll: function () {
      const enrolledCourse = this.enrolled_course
      const url = '/enrolled_courses/unenroll'
      return new Promise((resolve, reject) => {
        MANAGER_API.delete(url, { params: enrolledCourse })
          .then(response => {
            const data = response.data
            this.updateEnrollment()
            if (data.result.isError === false) {
              this.$notify({
                group: 'enrollment',
                type: 'info',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.processed = true
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    drop: function (enrolledCourse, course, student) {
      const url = 'programs/' + this.program.slug + '/enrolled_courses/drop'
      return new Promise((resolve, reject) => {
        API.delete(url, { params: enrolledCourse })
          .then(response => {
            const data = response.data
            this.updateEnrollment()
            if (data.result.isError === false) {
              this.$notify({
                group: 'enrollment',
                type: 'info',
                title: course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.processed = true
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: course.name + ' not dropped for ' + student.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: course.name + ' not dropped for ' + student.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    delete: function (enrolledCourse, course, student) {
      const url = 'enrolled_courses/delete'
      return new Promise((resolve, reject) => {
        MANAGER_API.delete(url, { params: enrolledCourse })
          .then(response => {
            const data = response.data
            this.updateEnrollment()
            if (data.result.isError === false) {
              this.$notify({
                group: 'enrollment',
                type: 'info',
                title: course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.processed = true
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: course.name + ' not dropped for ' + student.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: course.name + ' not dropped for ' + student.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    }
  }
}
