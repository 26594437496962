<template>
  <div>
    <template v-if="course.waitlist === 'none'">
      <p class="Lead">
        Class is full, contact {{ program.admin_name }} {{ program.phone }}
        <a :href="'mailto:' + program.admin_email">{{ program.admin_email }}</a>
      </p>
      <p v-if="enrolledStudents.length > 0">
        {{ enrolledStudents | toList }} is enrolled.
      </p>
    </template>
    <template v-else-if="course.waitlist === 'notify'">
      <div class="btn-group" v-if="students">
        <template v-for="student in studentsWhoCanEnroll">
          <button
            v-if="isStudentEnrolled(student)"
            class="btn btn-outline-info disabled"
            disabled
            :key="student.id"
          >
            {{ student.name }} enrolled
          </button>
          <button
            v-else-if="inWaitlist(student)"
            :key="student.id"
            v-on:click.prevent="removeFromWaitlist(student, course, $event)"
            class="btn btn-info"
          >
            <font-awesome-icon icon="business-time" />
            Remove {{ student.first }} from waitlist
          </button>
          <button
            v-else
            :key="student.id"
            :title="student.name + ' will be added to notification list'"
            v-on:click.prevent="addToWaitlist(student, $event)"
            class="btn btn-warning"
          >
            <font-awesome-icon icon="business-time" />
            Add {{ student.first }} to notification
          </button>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="btn-group" v-if="students">
        <template v-for="student in studentsWhoCanEnroll">
          <div v-if="isStudentEnrolled(student)" :key="student.id">
            {{ student.name }} enrolled
          </div>
          <button
            v-else-if="inWaitlist(student)"
            :key="student.id"
            v-on:click.prevent="removeFromWaitlist(student, course, $event)"
            class="btn btn-info"
          >
            <font-awesome-icon icon="business-time" />
            {{ student.first }}
          </button>
          <button
            v-else
            :key="student.id"
            :title="student.name + ' will be added to waitlist'"
            v-on:click.prevent="addToWaitlist(student, $event)"
            class="btn btn-warning"
          >
            <font-awesome-icon icon="business-time" />
            {{ student.first }}
          </button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import isEmpty from 'lodash/isEmpty'
export default {
  name: 'WaitlistButtons',
  mixins: [courseMethods],
  props: ['course'],
  data () {
    return {
      addedToWaitlist: {}
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    inWaitlist (student) {
      if (!isEmpty(this.addedToWaitlist[student.id])) {
        return true
      }
      return false
    },
    isStudentEnrolled (student) {
      const course = this.course
      if (
        typeof course.students !== 'undefined' &&
        course.students.length > 0
      ) {
        const inCourse = course.students.find(function (checkStudent) {
          return checkStudent.id === student.id
        })
        if (typeof inCourse !== 'undefined') {
          return true
        }
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      waitlist: 'waitlist'
    }),
    studentsWhoCanEnroll: function () {
      function isInGrade (value) {
        if (typeof value.grade === 'undefined') {
          return false
        }
        return this.includes(value.grade)
      }

      if (typeof this.students !== 'undefined') {
        let result = this.students.filter(isInGrade, this.course.grade_keys)
        if (result.length === 0) {
          // try with values
          result = this.students.filter(isInGrade, this.course.grades)
        }
        return result
      } else {
        return []
      }
    },
    enrolledStudents: function () {
      const vm = this
      let result = this.students.filter(function (student) {
        return vm.isStudentEnrolled(student)
      })
      result = result.map(function (student) {
        return student.first
      })
      return result
    }
  }
}
</script>

<style scoped></style>
