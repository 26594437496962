import { mapGetters, mapActions } from 'vuex'
import {
  UPDATE_ENROLLED_COURSES,
  UPDATE_ENROLLMENT,
  UPDATE_CALENDAR
} from '../../constants/mutation-types'
import { API, MANAGER_API } from '../../utilities/http-common'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { isNull, isEmpty } from 'lodash/core'
const moment = extendMoment(Moment)
moment.tz.setDefault('America/Phoenix')

export const calendarMethods = {
  computed: {
    ...mapGetters({
      member: 'registrationMember',
      registration: 'registration',
      enrolledCourses: 'enrolledCourses',
      program: 'program',
      allowOverEnroll: 'allowOverEnroll'
    }),
    isCalendar: function () {
      if (isNull(this.course.calendar_id) && !isNull(this.course.session_id)) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES,
      updateCalendar: UPDATE_CALENDAR,
      updateEnrollment: UPDATE_ENROLLMENT
    }),
    /**
     *
     * @param student
     * @param date
     * @param template
     * @param start
     * @param end
     * @returns {Promise<any>}
     */
    addDayToCart (student, date, template, start, end) {
      const days = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        member_id: this.member.id,
        days: [
          {
            student_id: student.id,
            date: date,
            template_id: template.id,
            start: start,
            end: end,
            first: student.first,
            name: student.name
          }
        ],
        start: start,
        end: end
      }
      const url =
        '/programs/' + this.program.slug + '/enrolled_courses/add_calendar'
      return new Promise((resolve, reject) => {
        API.put(url, days)
          .then(response => {
            const data = response.data
            if (typeof data !== 'undefined') {
              if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === false
              ) {
                this.updateEnrolledCourses(data.enrolled_courses)
                this.$emit('added-to-cart', {
                  student: this.student,
                  course: template,
                  date: date,
                  start: start,
                  end: end
                })
                resolve(data)
              } else if (data.result.isError === true) {
                reject(data)
              }
            } else {
              reject(response)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    /**
     * directly enroll a student in a day (will fail if it is a non manager member and there is not
     * enough credit balance to pay for it)
     * @param student
     * @param date
     * @param template
     * @param start
     * @param end
     * @returns {Promise<unknown>}
     */
    enrollStudentInDay (student, date, template, start, end) {
      console.log('calendar.enrollStudentInDay', student, date, template, start, end)
      const day = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        student_id: student.id,
        date: date,
        template_id: template.id,
        start: start,
        end: end,
        first: student.first,
        name: student.name,
        site_calendar_id: this.calendar.id
      }
      const url = '/programs/' + this.program_slug + '/enrolled_courses/enroll_day'
      const promise = new Promise((resolve, reject) => {
        API.put(url, day)
          .then(response => {
            const data = response.data
            console.log('enrollStudentInDay.then', data)
            if (typeof data !== 'undefined') {
              if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === false
              ) {
                console.log('calendar.enrollDay', data)
                if (!isEmpty(data.enrolled_course)) {
                  this.$notify({
                    group: 'enrollment',
                    type: 'success',
                    title: data.enrolled_course.name,
                    text:
                      student.name +
                      ' was signed up for ' +
                      data.enrolled_course.name +
                      ' on ' +
                      moment(date).format('L'),
                    duration: 2000,
                    speed: 1000
                  })

                  this.updateEnrollment()
                  resolve(data)
                }
              } else if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === true
              ) {
                this.updateEnrollment()
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: template.name,
                  text: data.result.message,
                  duration: 2000,
                  speed: 1000
                })
                reject(data)
              }
            } else {
              this.updateEnrollment()
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text:
                  'unknown error has occurred trying to enroll ' +
                  student.name +
                  ' in ' +
                  template.name +
                  ' on ' +
                  moment(date).format('L'),
                duration: 2000,
                speed: 1000
              })
              reject(response)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text:
                'unknown error has occurred trying to enroll ' +
                student.name +
                ' in ' +
                template.name +
                ' on ' +
                moment(date).format('L'),
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
      console.log('promise', promise)
      return promise
    },
    /**
     * @deprecated use enrollStudentInDay I think it is better implementation but essentially identical
     * @param student
     * @param date
     * @param template
     * @param start
     * @param end
     * @returns {Promise<unknown>}
     */
    enrollDayWithBalance: function (student, date, template, start, end) {
      const days = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        member_id: this.member.id,
        days: [
          {
            student_id: student.id,
            date: date,
            template_id: template.id,
            start: start,
            end: end,
            first: student.first,
            name: student.name,
            site_calendar_id: this.calendar.id
          }
        ]
      }
      const url = '/enrolled_courses/enroll_days_with_balance'
      return new Promise((resolve, reject) => {
        API.put(url, days)
          .then(response => {
            const data = response.data
            this.updateEnrollment()
            if (data.result.isError === false) {
              this.$notify({
                group: 'enrollment',
                type: 'success',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              this.$emit('enrolled-course', { student: student, course: this.course })
              resolve(data)
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              reject(data)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    managerEnrollDay (student, member, date, template, start, end) {
      return this.enrollDay(student, member, date, template, start, end)
    },
    enrollDay (student, member, date, template, start, end) {
      const days = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        member_id: member.id,
        allow_over_enroll: this.allowOverEnroll,
        days: [
          {
            student_id: student.id,
            date: date,
            template_id: template.id,
            start: start,
            end: end,
            first: student.first,
            name: student.name,
            site_calendar_id: this.calendar.id,
            allow_over_enroll: this.allowOverEnroll
          }
        ]
      }
      const url = '/enrolled_courses/enroll_calendar'
      const promise = new Promise((resolve, reject) => {
        MANAGER_API.put(url, days)
          .then(response => {
            const data = response.data
            if (typeof data !== 'undefined') {
              if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === false
              ) {
                console.log('calendar.enrollDay', data)
                if (!isEmpty(data.full_courses)) {
                  data.full_courses.forEach(fullCourse => {
                    this.$notify({
                      group: 'enrollment',
                      type: 'warn',
                      title: template.name,
                      text:
                        student.name +
                        ' was not signed up for ' +
                        template.name +
                        ' it was full.  Turn on Force Over Enrollment to sign up',
                      duration: 2000,
                      speed: 1000
                    })
                  })
                  this.$emit('enrolled-course', {
                    student: student,
                    course: template,
                    date: date,
                    start: start,
                    end: end
                  })
                  this.updateEnrollment()
                  reject(data)
                } else if (!isEmpty(data.failed_courses)) {
                  data.failed_courses.forEach(failedCourse => {
                    this.$notify({
                      group: 'enrollment',
                      type: 'error',
                      title: failedCourse.name,
                      text:
                        student.name +
                        ' was not signed up for ' +
                        failedCourse.name,
                      duration: 2000,
                      speed: 1000
                    })
                  })
                  this.updateEnrollment()
                  reject(data)
                } else if (!isEmpty(data.enrolled_courses)) {
                  data.enrolled_courses.forEach(enrolledCourse => {
                    this.$notify({
                      group: 'enrollment',
                      type: 'success',
                      title: enrolledCourse.name,
                      text:
                      student.name +
                      ' was signed up for ' +
                      enrolledCourse.name +
                      ' on ' +
                      moment(date).format('L'),
                      duration: 2000,
                      speed: 1000
                    })
                  })
                  this.updateEnrollment()
                  resolve(data)
                }
              } else if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === true
              ) {
                this.updateEnrollment()
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: template.name,
                  text: data.result.message,
                  duration: 2000,
                  speed: 1000
                })
                reject(data)
              }
            } else {
              this.updateEnrollment()
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text:
                  'unknown error has occurred trying to enroll ' +
                  student.name +
                  ' in ' +
                  template.name +
                  ' on ' +
                  moment(date).format('L'),
                duration: 2000,
                speed: 1000
              })
              reject(response)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text:
                'unknown error has occurred trying to enroll ' +
                student.name +
                ' in ' +
                template.name +
                ' on ' +
                moment(date).format('L'),
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
      console.log('promise', promise)
      return promise
    },
    unenrollDay (student, member, date, template, enrolledCourse) {
      const enrollment = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        student_id: student.id,
        member_id: member.id,
        course_id: enrolledCourse.course_id
      }
      const url = '/enrolled_courses/unenroll_calendar'
      return new Promise((resolve, reject) => {
        MANAGER_API.delete(url, { params: enrollment })
          .then(response => {
            const data = response.data
            if (typeof data !== 'undefined') {
              if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === false
              ) {
                this.updateEnrollment()
                this.$notify({
                  group: 'enrollment',
                  type: 'success',
                  title: template.name,
                  text:
                    student.name +
                    ' was unenrolled from ' +
                    template.name +
                    ' on ' +
                    moment(date).format('L'),
                  duration: 2000,
                  speed: 1000
                })
                resolve(data)
              } else if (data.result.isError === true) {
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: template.name,
                  text: data.result.message,
                  duration: 2000,
                  speed: 1000
                })
                reject(data)
              }
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text:
                  'unknown error has occurred trying to unenroll from ' +
                  student.name +
                  ' from ' +
                  template.name +
                  ' on ' +
                  moment(date).format('L'),
                duration: 2000,
                speed: 1000
              })
              reject(response)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text:
                'unknown error has occurred trying to unenroll ' +
                student.name +
                ' from ' +
                template.name +
                ' on ' +
                moment(date).format('L'),
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    dropDay (student, member, date, template, enrolledCourse) {
      const enrollment = {
        registration_id: this.registration.id,
        program_slug: this.program_slug,
        student_id: student.id,
        member_id: member.id,
        course_id: enrolledCourse.course_id
      }
      const url = '/enrolled_courses/drop_calendar'
      return new Promise((resolve, reject) => {
        MANAGER_API.delete(url, { params: enrollment })
          .then(response => {
            const data = response.data
            if (typeof data !== 'undefined') {
              if (
                typeof data.result !== 'undefined' &&
                typeof data.result.isError !== 'undefined' &&
                data.result.isError === false
              ) {
                this.updateEnrollment()
                this.$notify({
                  group: 'enrollment',
                  type: 'info',
                  title: template.name,
                  text:
                    student.name +
                    ' was dropped from ' +
                    template.name +
                    ' on ' +
                    moment(date).format('L'),
                  duration: 2000,
                  speed: 1000
                })
                resolve(data)
              } else if (data.result.isError === true) {
                this.$notify({
                  group: 'enrollment',
                  type: 'error',
                  title: template.name,
                  text: data.result.message,
                  duration: 2000,
                  speed: 1000
                })
                reject(data)
              }
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text:
                  'unknown error has occurred trying to drop from ' +
                  student.name +
                  ' from ' +
                  template.name +
                  ' on ' +
                  moment(date).format('L'),
                duration: 2000,
                speed: 1000
              })
              reject(response)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text:
                'unknown error has occurred trying to drop ' +
                student.name +
                ' from ' +
                template.name +
                ' on ' +
                moment(date).format('L'),
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    removeDayFromCartCourse (student, course, cart_course_id) {
      const data = {
        course_id: course.id,
        student_id: student.id,
        cart_course_id: cart_course_id
      }
      const url = 'programs/' + this.program.slug + '/enrolled_courses/delete'
      return new Promise((resolve, reject) => {
        API.delete(url, { params: data })
          .then(response => {
            const data = response.data
            this.$notify({
              group: 'enrollment',
              type: 'info',
              title: course.name,
              text: response.message,
              duration: 2000,
              speed: 1000
            })
            this.updateEnrolledCourses(data.enrolled_courses)
            resolve(data)
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: course.name,
              text: 'Could not delete ' + course.name + ' from cart!',
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    removeDayFromCart (student, course, calendar, registration) {
      console.log('calendar.removeDayFromCart', student, course, calendar, registration)
      const enrolledDay = {
        course_id: course.id,
        student_id: student.id,
        site_calendar_id: calendar.id,
        registration_id: registration.id
      }
      const url = 'programs/' + this.program.slug + '/enrolled_courses/delete'
      return new Promise((resolve, reject) => {
        API.delete(url, { params: enrolledDay })
          .then(response => {
            const data = response.data
            this.$notify({
              group: 'enrollment',
              type: 'info',
              title: course.name,
              text: response.message,
              duration: 2000,
              speed: 1000
            })
            this.updateEnrolledCourses(data.enrolled_courses)
            resolve(data)
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: course.name,
              text: 'Could not delete ' + course.name + ' from cart!',
              duration: 2000,
              speed: 1000
            })
            reject(e)
          })
      })
    },
    templateContained (student) {
      const overlaps = this.overlap_courses
      if (isNull(overlaps) || typeof overlaps === 'undefined') {
        return false
      }
      if (typeof overlaps[student.id] !== 'undefined') {
        const studentOverlaps = overlaps[student.id]
        const course = this.course
        const courseStart = course.startTime
        const courseEnd = course.endTime
        const courseRange = moment.range(courseStart, courseEnd)
        let overlappingRange = null
        for (const overlap of studentOverlaps) {
          if (overlap.name.includes(course.name)) {
            return true
          }
          const overlapStart = overlap.startTime
          const overlapEnd = overlap.endTime
          const overlapRange = moment.range(overlapStart, overlapEnd)
          if (overlappingRange == null) {
            overlappingRange = overlapRange
          } else {
            overlappingRange = overlappingRange.add(overlapRange, {
              adjacent: true
            })
          }
        }
        if (
          overlappingRange !== null &&
          overlappingRange.contains(courseRange)
        ) {
          return true
        }
      }
      return false
    },
    courseOverlapped (student) {
      const overlaps = this.overlap_courses
      if (typeof overlaps[student.id] !== 'undefined') {
        const courses = overlaps[student.id]
        const course = this.course
        const start = moment(course.startTime)
        const end = moment(course.endTime)
        let show = false
        if (typeof courses !== 'undefined' && courses.length > 0) {
          courses.some(course => {
            const startTime = moment(course.startTime)
            const endTime = moment(course.endTime)
            const range = moment.range(startTime, endTime)
            show =
              show ||
              ((range.contains(start) || range.contains(end)) &&
                !(range.contains(start) && range.contains(end)))
            return show
          })
        }
        return show
      }
      return false
    }
  }
}
