<!--suppress JSUnfilteredForInLoop -->
<template>
  <button
    v-if="inCart"
    v-on:click.prevent="removeFromCart()"
    class="btn btn-warning"
    :class="{
      disabled: disabled
    }"
    :disabled="disabled"
  >
    <font-awesome-icon icon="cart-arrow-down" />
    {{ student.first }} {{ cartTime }}
  </button>
  <span v-else-if="isEnrolled && !isDropped && !isEditing" class="btn-group">
    <button class="btn btn-outline-info disabled" disabled>
      {{ student.first }} {{ enrolledTime }}
    </button>
    <button
      v-if="allowChange"
      @click.prevent="editing(!isEditing)"
      class="btn btn-warning"
      title="change selected time"
    >
      <cs-icon icon="edit"></cs-icon>
    </button>
    <button
      v-if="allowChange"
      :disabled="disabled"
      @click.prevent="dropEnrolled()"
      class="btn btn-danger"
      title="Drop selected time"
    >
      <cs-icon icon="drop"></cs-icon>
    </button>
  </span>
  <span v-else-if="!blockEnrollmentWithUnpaid">
    <transition name="fade">
      <div class="alert alert-warning" v-if="isEditing">
        You are currently chosing to change {{ student.first }}'s time of
        {{ enrolledTime }}
      </div>
    </transition>
    <template v-if="usePulldown">
      <div v-if="course.allow_start" :class="{ 'bg-warning p-1': !isValid }">
        <multiselect
          :close-on-select="true"
          :disabled="disabled"
          :options="dropTimes"
          :placeholder="student.first + ' Drop Off'"
          :searchable="false"
          :show-labels="false"
          :value="studentSelection.selectedStart"
          @input="setSelectedStart"
          label="time"
          track-by="time"
        >
          <template slot="singleLabel" slot-scope="{ option }">
            <div class="p-0">
              <template v-if="course.allow_start && course.allow_end">
                <strong>{{ student.first }}</strong> selected
                <strong> {{ option }}</strong>
              </template>
              <template v-else
                ><strong>{{ student.first }}</strong> in cart
                <strong> {{ option }}</strong>
              </template>
            </div>
          </template>
        </multiselect>
      </div>
      <multiselect
        :close-on-select="true"
        :disabled="disabled"
        :options="pickupTimes"
        :searchable="false"
        :show-labels="false"
        :value="studentSelection.selectedEnd"
        @input="setSelectedEnd"
        label="time"
        track-by="time"
        v-if="course.allow_end"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <div class="p-0">
            <template v-if="course.allow_start && course.allow_end">
              <strong>{{ student.first }}</strong> selected
              <strong> {{ option }}</strong>
            </template>
            <template v-else
              ><strong>{{ student.first }}</strong> in cart
              <strong> {{ option }}</strong>
            </template>
          </div>
        </template>
        <template slot="placeholder">{{ student.first }} Pick Up</template>
      </multiselect>
      <div v-if="!isValid" class="text-danger small">
        You need to select both a drop and a pickup time for
        {{ student.first }}
      </div>

      <button
        type="button"
        class="btn btn-warning"
        v-if="isEditing"
        @click.prevent="isEditing = false"
      >
        <font-awesome-icon icon="ban"> Cancel</font-awesome-icon>
      </button>
    </template>
    <template v-else>
      <template v-if="course.allow_start">
        <p>Drop off: {{ student.first }}</p>
        <button
          v-on:click.prevent="addCourseToCartForStudent(time, null)"
          v-for="time in selectTimes"
          :key="'drop' + student.id + time"
          class="btn btn-success btn-sm m-1"
          :class="{
            disabled: disabled
          }"
          :disabled="disabled"
        >
          {{ time }}
        </button>
      </template>
      <template v-if="course.allow_end">
        <p>Pick up: {{ student.first }}</p>
        <button
          v-on:click.prevent="addCourseToCartForStudent(null, time)"
          v-for="time in selectTimes"
          :key="'pickup' + student.id + time"
          class="btn btn-success btn-sm m-1"
          :class="{
            disabled: disabled
          }"
          :disabled="disabled"
        >
          {{ time }}
        </button>
      </template>
      <button
        type="button"
        class="btn btn-warning"
        v-if="isEditing"
        @click.prevent="isEditing = false"
      >
        <font-awesome-icon icon="ban"></font-awesome-icon>
      </button>
    </template>
  </span>
</template>

<script>
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'HourlyButton',
  mixins: [courseMethods, calendarMethods],
  props: {
    student: { type: Object, required: true },
    course: { type: Object, required: true },
    day: { type: Object, required: false },
    calendar: { type: Object, required: false },
    enrolled: {
      type: [Array, Object],
      required: false
    }
  },
  data () {
    return {
      studentSelection: [],
      disabled: false,
      isEditing: false
    }
  },
  created () {
    this.program_slug = this.program.slug
    this.studentSelection = {
      name: this.student.name,
      first: this.student.first,
      id: this.student.id,
      selectedStart: null,
      selectedEnd: null
    }
  },
  mounted () {},
  watch: {
    course: function () {
      this.disabled = false
      this.isEditing = false
    },
    inCart: function () {
      this.disabled = false
      this.isEditing = false
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    dropEnrolled () {
      var enrolled = this.enrolled_course
      this.disabled = true
      this.drop(enrolled, this.course, this.student)
        .then(function () {})
        .catch(function () {})
    },
    editing (newValue) {
      if (newValue) {
        this.studentSelection.selectedStart = null
        this.studentSelection.selectedEnd = null
      }
      this.isEditing = newValue
    },
    removeFromCart () {
      this.disabled = true
      if (this.isCalendar) {
        this.removeDayFromCart(
          this.student,
          this.course,
          this.calendar,
          this.registration
        )
          .then(result => {
            const data = result.result
            if (!isNull(data.isError) && !data.isError) {
              this.$notify({
                group: 'enrollment',
                type: 'info',
                title: this.course.name,
                text: data.message
              })
              this.studentSelection.selectedStart = null
              this.studentSelection.selectedEnd = null
            } else {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.course.name,
                text: data.message
              })
            }
          })
          .catch(e => {
            this.$notify({
              group: 'enrollment',
              type: 'error',
              title: this.course.name,
              text: e.message
            })
          })
      } else {
        this.removeCourseFromCart(
          this.student,
          this.course,
          this.session,
          this.program.slug,
          this.registration
        )
        this.studentSelection.selectedStart = null
        this.studentSelection.selectedEnd = null
      }
    },
    addCourseToCartForStudent (start, end) {
      this.disabled = true
      if (isNull(this.course.session_id)) {
        if (!isNull(this.course.site_calendar_id)) {
          this.addDayToCart(
            this.student,
            this.day.date,
            this.course,
            start,
            end
          )
            .then(data => {
              if (!isEmpty(data.students)) {
                const students = data.students
                for (const studentId in students) {
                  const student = students[studentId]
                  var message = ''
                  if (!isEmpty(student.courses)) {
                    const courses = student.courses
                    var courseNames = []
                    for (const courseId in courses) {
                      const course = courses[courseId]
                      const courseDay = moment(course.day).format('M/D')
                      courseNames.push(course.name + ' ' + courseDay)
                    }
                    message = courseNames.join(',')
                  }
                  this.$notify({
                    group: 'enrollment',
                    type: 'success',
                    title: student.first + ' added to cart',
                    text: message,
                    duration: 2000,
                    speed: 1000
                  })
                }
              }
            })
            .catch(() => {
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: this.student.name + ' problem',
                text:
                  this.student.name +
                  ' problem adding student to cart for day.',
                duration: 2000,
                speed: 1000
              })
            })
        }
      } else {
        this.addTimeToCart(this.student, start, end)
          .then(() => {
            // success
          })
          .catch(() => {
            this.disabled = false
          })
      }
    },
    // sets the start time and can enroll/add to cart the student
    selectedStart () {
      return this.studentSelection.startSelection
    },
    setSelectedStart (selected) {
      const time = selected.time
      this.studentSelection.selectedStart = time
      this.shouldAddTimesToCart()
    },
    setSelectedEnd (selected) {
      const time = selected.time
      this.studentSelection.selectedEnd = time
      this.shouldAddTimesToCart()
    },
    shouldAddTimesToCart () {
      const course = this.course
      if (course.allow_start && course.allow_end) {
        if (
          !isEmpty(this.studentSelection.selectedStart) &&
          !isEmpty(this.studentSelection.selectedEnd)
        ) {
          this.addCourseToCartForStudent(
            this.studentSelection.selectedStart,
            this.studentSelection.selectedEnd
          )
        } else {
          let message = isEmpty(this.studentSelection.selectedStart)
            ? 'Select Pickup Time for '
            : 'Select Drop Off Time for '
          message = message + this.student.name
          this.$notify({
            group: 'enrollment',
            type: 'warning',
            title: this.course.name,
            text: message,
            duration: 2000,
            speed: 1000
          })
        }
      } else if (course.allow_start || course.allow_end) {
        this.addCourseToCartForStudent(
          this.studentSelection.selectedStart,
          this.studentSelection.selectedEnd
        )
      }
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'isProgramManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    dropTimes () {
      var selectTimes = this.selectTimes.map(time => {
        return {
          time: time,
          student: this.studentSelection
        }
      })
      if (this.studentSelection.selectedEnd) {
        const selectedEnd = this.studentSelection.selectedEnd
        const index = selectTimes.findIndex(function (time) {
          return selectedEnd === time.time
        })
        return selectTimes.slice(0, index)
      }
      return selectTimes
    },
    pickupTimes () {
      var selectTimes = this.selectTimes.map(time => {
        return {
          time: time,
          student: this.studentSelection
        }
      })

      if (this.studentSelection.selectedStart) {
        const selectedEnd = this.studentSelection.selectedStart
        const index = selectTimes.findIndex(function (time) {
          return selectedEnd === time.time
        })
        return selectTimes.slice(index + 1)
      }
      return selectTimes
    },
    usePulldown: function () {
      return this.course.use_pulldown
    },
    cartTime () {
      var enrolled = this.cart
      if (!isEmpty(enrolled)) {
        const format = 'h:mma'
        if (!isEmpty(enrolled.start) && !isEmpty(enrolled.end)) {
          return (
            moment(enrolled.start).format(format) +
            '-' +
            moment(enrolled.end).format(format)
          )
        } else if (!isEmpty(enrolled.start)) {
          return moment(enrolled.start).format(format)
        } else if (!isEmpty(enrolled.end)) {
          return moment(enrolled.end).format(format)
        }
      }
      return ''
    },
    isValid () {
      if (this.course.allow_start && this.course.allow_end) {
        if (
          (isEmpty(this.studentSelection.selectedStart) &&
            !isEmpty(this.studentSelection.selectedEnd)) ||
          (!isEmpty(this.studentSelection.selectedStart) &&
            isEmpty(this.studentSelection.selectedEnd))
        ) {
          return false
        }
      }
      return true
    },
    enrolledTime () {
      var enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && !enrolled.dropped) {
        const format = 'h:mma'
        if (!isEmpty(enrolled.start) && !isEmpty(enrolled.end)) {
          return (
            moment(enrolled.start).format(format) +
            '-' +
            moment(enrolled.end).format(format)
          )
        } else if (!isEmpty(enrolled.start)) {
          return moment(enrolled.start).format(format)
        } else if (!isEmpty(enrolled.end)) {
          return moment(enrolled.end).format(format)
        }
      }
      return ''
    },
    enrolledStart () {
      var enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && !isEmpty(enrolled.start)) {
        return moment(enrolled.start)
      }
      return null
    },
    enrolledEnd () {
      var enrolled = this.enrolled_course
      if (!isEmpty(enrolled) && !isEmpty(enrolled.end)) {
        return moment(enrolled.end)
      }
      return null
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
