<template>
  <div
    v-if="students && shouldShow"
    class="list-group list-group-flush"
    :class="{
      'bg-warning-light': isManager && !course.public
    }"
  >
    <span class="w-100 text-center">
      <strong class="px-2" v-html="course.name" /><span
        v-if="isManager && !course.public"
        class="small badge badge-warning"
        >Private</span
      >
    </span>
    <div class="btn-group px-1">
      <template v-if="!isManager">
        <template v-for="student in studentSelections">
          <template v-if="getStudentEnrolled(student)">
            <span
              :key="'message-' + course.id + '-student-' + student.id"
              class="text-muted small"
              >{{ student.first }} enrolled</span
            >
          </template>
          <app-standard-button
            v-else
            :course="course"
            :student="student"
            :day="day"
            :enrolled="enrolled"
            :calendar="calendar"
            :is-student-enrolled="getStudentEnrolled(student)"
            :enrolled-label="enrolledLabel"
            :key="'add-cart-' + course.id + '-student-' + student.id"
          >
          </app-standard-button>
        </template>
      </template>
      <template v-else>
        <template v-for="student in studentsWhoCanEnroll">
          <template v-if="getStudentEnrolled(student)">
            <span
              :key="'message-' + course.id + '-student-' + student.id"
              class="text-muted small"
              >{{ student.first }} {{  enrolledLabel }}</span
            >
          </template>
          <app-manager-standard-button
            :course="course"
            :student="student"
            :day="day"
            :enrolled="enrolled"
            :calendar="calendar"
            :is-student-enrolled="getStudentEnrolled(student)"
            :enrolled-label="enrolledLabel"
            :key="'manager-enroll-' + course.id + '-student-' + student.id"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import ManagerStandardButton from './ManagerStandardButton'
import StandardButton from './StandardButton'
import { calendarMethods } from '@/components/mixins/calendar'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'StandardButtons',
  mixins: [courseMethods, calendarMethods],
  components: {
    appManagerStandardButton: ManagerStandardButton,
    appStandardButton: StandardButton
  },
  props: {
    course: { type: Object, required: true },
    day: { type: Object, required: false },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    calendar: {
      type: Object,
      required: false
    },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    signupClosed: {
      type: String,
      requred: false,
      default: ''
    },
    closedReason: {
      type: String,
      required: false,
      default: 'Signup is closed'
    },
    enrolled: {
      type: Object,
      required: false
    },
    overlap_courses: {
      type: Object,
      required: false
    },
    enrolledLabel: {
      type: String,
      required: false,
      default: 'enrolled'
    }
  },
  data () {
    return {
      studentSelections: []
    }
  },
  created () {
    const students = this.studentsWhoCanEnroll
    this.studentSelections = students.map(function (student) {
      return {
        name: student.name,
        first: student.first,
        id: student.id,
        selectedStart: null,
        selectedEnd: null
      }
    })
  },
  mounted () {},
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    getStudentEnrolled (student) {
      const templateContained = this.templateContained(student)
      const enrolledCourseStudent = this.enrolledCourseStudent(
        this.course,
        student
      )
      return templateContained || !isEmpty(enrolledCourseStudent)
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager'
    }),
    shouldShow: function () {
      if (this.course.public || this.isManager) {
        return true
      } else {
        for (const student of this.studentsWhoCanEnroll) {
          if (this.enrolledCourseStudent(this.course, student)) {
            return true
          }
        }
      }
      return false
    },
    studentsWhoCanEnroll: function () {
      return this.studentsWhoCanEnrollInCourse(this.course, this.students)
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}

.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
