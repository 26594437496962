<template>
  <button
    :class="{ disabled: disabled }"
    :disabled="disabled"
    class="btn btn-danger"
    v-if="isStudentEnrolled && !isDropped"
    v-on:click.prevent="unenrollCourse()"
  >
    <font-awesome-icon icon="user-minus" />
    {{ student.first }}
  </button>
  <button
    :class="{ disabled: disabled }"
    :disabled="disabled"
    :key="student.id"
    class="btn btn-primary"
    v-else
    v-on:click.prevent="enrollCourse"
  >
    <font-awesome-icon icon="user-plus" />
    {{ student.first }}
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'
import { isNull } from 'lodash/core'

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'ManagerStandardButton',
  mixins: [courseMethods, calendarMethods],
  props: {
    student: {
      type: Object,
      required: true
    },
    course: {
      type: Object,
      required: true
    },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    calendar: {
      type: Object,
      required: false
    },
    day: {
      type: Object,
      required: false
    },
    enrolled: {
      type: Object,
      required: false
    },
    isStudentEnrolled: {
      type: Boolean,
      required: true
    },
    enrolledLabel: {
      type: String,
      required: false,
      default: 'enrolled'
    }
  },
  data () {
    return {
      disabled: false
    }
  },
  created () {
    this.program_slug = this.program.slug
  },
  mounted () {},
  watch: {
    course: function () {
      this.disabled = false
    }
  },
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    enrollCourse () {
      this.disabled = true
      if (isNull(this.course.session_id)) {
        this.enrollDay(
          this.student,
          this.registration.member,
          this.day.date,
          this.course,
          this.course.startTime,
          this.course.endTime
        )
      } else {
        this.enroll(this.student)
          .then(() => {
            //
          })
          .catch(() => {
            this.disabled = false
          })
      }
    },
    unenrollCourse () {
      this.disabled = true
      this.unenroll()
        .then(() => {
          //
        })
        .catch(() => {
          this.disabled = false
        })
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'isProgramManager'
    }),
    studentCanEnroll: function () {
      const grades = this.course.grade_keys
      if (grades.indexOf(this.student.grade) >= 0) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}
.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
