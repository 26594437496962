<template>
  <div class="py-2">
    <div
      class="card"
      :class="{
        'border-danger': spaceDanger,
        'border-warning': spaceWarning
      }"
    >
      <img
        v-if="
          typeof displayCourse.image !== 'undefined' &&
            typeof displayCourse.image.standard !== 'undefined'
        "
        v-responsive.md.lg.xl
        v-bind:src="displayCourse.image.standard"
        v-bind:alt="displayCourse.image.description"
        class="card-image-top mx-auto"
      />
      <div
        class="card-header d-flex justify-content-between align-items-center"
        :class="{
          'bg-danger': spaceDanger,
          'bg-warning': spaceWarning,
          'bg-danger': blockEnrollmentWithUnpaid
        }"
      >
        <img
          v-if="
            typeof displayCourse.image !== 'undefined' &&
              typeof displayCourse.image.thumbnail !== 'undefined'
          "
          v-responsive.sm
          v-bind:src="displayCourse.image.thumbnail"
          v-bind:alt="displayCourse.image.description"
          class="img-thumbnail"
        /><strong v-html="displayCourse.name"></strong>
        <button
          @click.prevent="showBrief = !showBrief"
          v-if="hasInfo"
          class="btn btn-info"
        >
          <font-awesome-icon icon="info" />
        </button>
      </div>
      <transition name="fade" key="displayCourse.id">
        <div v-if="showBrief" class="card-body" :class="infoStyle">
          <span v-html="info"></span>
          <button
            v-if="hasMore"
            v-on:click="showMore = !showMore"
            class="btn btn-info"
          >
            More...
          </button>
          <div v-if="showMore"><span v-html="displayCourse.description"></span></div>
        </div>
      </transition>
      <div class="list-group list-group-flush">
        <div v-if="hasLateFee" class="list-group-item list-group-item-warning">
          Enrollments after {{ dateTimeString(displayCourse.late_fee_date) }} will be assessed a
          {{ currency(session.late_fee) }} late registration fee.
        </div>
        <div
          v-if="displayCourse.space <= 0"
          class="list-group-item list-group-item-danger"
        >
          This class is full
        </div>
        <div
          v-else-if="blockEnrollmentWithUnpaid"
          class="list-group-item list-group-item-danger"
        >
          New enrollments are blocked due to unpaid registrations
        </div>
        <div
          v-else-if="spaceDanger"
          class="list-group-item list-group-item-danger"
        >
          This class is almost full, remember that is not enrolled until you
          have paid for it.
        </div>
        <div
          v-if="spaceWarning"
          class="list-group-item list-group-item-warning"
        >
          This class is approaching capacity, remember that is not enrolled
          until you have paid for it.
        </div>

        <div
          class="list-group-item  d-flex justify-content-between align-items-center"
        >
          <span>{{ displayCourse.grade_list }}</span
          ><span
            >{{ timeRange(displayCourse.startTime,displayCourse.endTime) }}</span
          ><span>{{ displayCourse.days }}</span>
        </div>
        <div
          class="list-group-item  d-flex justify-content-between align-items-center"
        >
          <span v-if="displayCourse.fee > 0">Fee: {{ currency(displayCourse.fee) }}</span>
          <span v-if="displayCourse.hourly_fee > 0"
            >Hourly: {{ currency(displayCourse.hourly_fee) }}/hr</span
          >
          <span v-if="displayCourse.materialFee > 0"
            >Materials: {{ currency(displayCourse.materialFee) }}</span
          >
        </div>
      </div>
      <div
        v-if="registrationOpen && loggedIn"
        class="card-footer"
        :class="{ 'bg-danger-light': displayCourse.space < spaceDanger }"
      >
        <p v-if="displayCourse.space <= 0 && isManager" class="text-danger">
          Class is full: Managers can forcibly enroll.
        </p>
        <app-enroll-buttons
          v-if="loggedIn && (displayCourse.space > 0 || isManager)"
          :course="course"
          :students="students"
        ></app-enroll-buttons>
        <app-waitlist-buttons
          v-else-if="!blockEnrollmentWithUnpaid"
          :course="course"
        ></app-waitlist-buttons>
      </div>
    </div>
  </div>
</template>

<script>

import EnrollButtons from '@/components/shared/EnrollButtons.vue'
import WaitlistButtons from '@/components/shared/WaitlistButtons'
import { mapGetters } from 'vuex'
import { SIZE_WARNING, SIZE_ALERT } from '@/constants/application'

import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { currency } from '@/utilities/stringFormatters'
import { dateString, dateTimeString, dateRange, timeString, timeRange } from '@/utilities/dateFormatters'
import { courseMethods } from '@/components/mixins/course'
import { InitialState } from '@/utilities/cs-utilities'
export default {
  name: 'Course',
  mixins: [courseMethods],
  components: {
    appEnrollButtons: EnrollButtons,
    appWaitlistButtons: WaitlistButtons
  },
  props: ['course'],
  data () {
    return {
      showBrief: false,
      showMore: false,
      infoStyle: '',
      initialState: null,
      displayCourse: this.course
    }
  },
  created () {
    if (isEmpty(this.course)) {
      this.initializeState()
    }
  },
  mounted: function () {
    if (typeof this.displayCourse.show_info !== 'undefined') {
      switch (this.displayCourse.show_info) {
        case 'brief':
          this.showBrief = true
          this.showMore = false
          break
        case 'description':
          this.showBrief = true
          this.showMore = true
          break
        default:
          this.showBrief = false
          this.showMore = false
      }
    }
    if (
      typeof this.displayCourse.style_info !== 'undefined' &&
      this.displayCourse.style_info !== 'default'
    ) {
      this.infoStyle = 'alert alert-' + this.displayCourse.style_info
    }
  },
  methods: {
    isEmpty,
    currency,
    timeString,
    dateRange,
    dateString,
    dateTimeString,
    timeRange,
    initializeState () {
      const initialState = InitialState()
      console.log('CourseDetail.initialState.portals', initialState.portals, initialState.portals.course_detail_portal)
      this.initialState = JSON.parse(initialState.portals.course_detail_portal)
      if (!isEmpty(this.initialState.course)) {
        this.displayCourse = this.initialState.course
      }
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'loggedIn',
      students: 'students',
      enrolled_courses: 'enrolledCourses',
      registration: 'registration',
      session: 'selectedSession',
      member: 'member',
      isManager: 'isProgramManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    spaceWarning: function () {
      return (
        this.displayCourse.space > SIZE_ALERT && this.displayCourse.space <= SIZE_WARNING
      )
    },
    spaceDanger: function () {
      return this.displayCourse.space <= SIZE_ALERT
    },
    hasInfo: function () {
      return (
        !isEmpty(this.displayCourse.brief) || !isEmpty(this.displayCourse.description)
      )
    },
    hasMore: function () {
      if (this.hasInfo && this.info !== this.displayCourse.description) {
        return true
      }
      return false
    },
    info: function () {
      if (!isNil(this.course)) {
        if (!isEmpty(this.displayCourse.brief)) {
          return this.displayCourse.brief
        } else if (!isEmpty(this.displayCourse.description)) {
          return this.displayCourse.description
        }
      }
      return ''
    },
    hasLateFee: function () {
      if (this.registrationOpen) {
        if (this.session.late_fee > 0) {
          const now = moment()
          const lateFeeDate = this.displayCourse.late_fee_date
          if (now.isSameOrAfter(lateFeeDate)) {
            return true
          }
        }
      }
      return false
    },
    registrationOpen: function () {
      if (this.students != null && this.students.length >= 1) {
        if (
          typeof this.registration !== 'undefined' &&
          this.registration != null
        ) {
          if (
            this.session.registrationOpen ||
            (this.session.earlyRegistrationOpen && this.memberGte('staff')) ||
            this.displayCourse.state === 'OPEN' ||
            this.isManager
          ) {
            if (this.displayCourse.state !== 'CLOSED') {
              return !(!this.isManager && this.registration.closed)
            }
          }
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
