<!--suppress JSUnfilteredForInLoop -->
<template>
  <div
    class="list-group list-group-flush"
    :class="'bg-' + program.slug + '-light'"
  >
    <span
      class="w-100 text-center"
      v-if="!course.allow_start && !course.allow_end"
    >
      <strong class="px-2" v-html="course.name" />
    </span>
    <template v-if="!isManager">
      <div
        v-for="student in studentSelections"
        :key="student.id"
        class="list-group-item select-time p-2"
      >
        <template v-if="signupClosed">
          {{ signupClosedReason }}
        </template>
        <template
          v-if="
            (templateContained(student) ||
              enrolledCourseStudent(course, student)) &&
              !allowChange
          "
        >
          <span class="text-muted small">{{ student.first }} enrolled</span>
        </template>
        <app-standard-button
          v-else-if="!course.allow_start && !course.allow_end"
          :course="course"
          :student="student"
          :day="day"
          :enrolled="enrolled"
          :calendar="calendar"
          :is-student-enrolled="
            templateContained(student) || enrolledCourseStudent(course, student)
          "
          :key="'add-cart-' + course.id + '-student-' + student.id"
        >
        </app-standard-button>
        <app-hourly-button
          v-else
          :course="course"
          :day="day"
          :student="student"
          :calendar="calendar"
          :enrolled="enrolled"
        >
        </app-hourly-button>
      </div>
    </template>
    <template v-else>
      <div
        v-for="student in studentSelections"
        class="list-group-item select-time"
        :key="'enroll' + student.id"
      >
        <template
          v-if="
            (templateContained(student) ||
              enrolledCourseStudent(course, student)) &&
              !allowChange
          "
        >
          <span class="text-muted small">{{ student.first }} enrolled</span>
        </template>
        <app-manager-standard-button
          v-else-if="!course.allow_start && !course.allow_end"
          :course="course"
          :student="student"
          :day="day"
          :enrolled="enrolled"
          :calendar="calendar"
          :key="'manager-enroll-' + course.id + '-student-' + student.id"
          :is-student-enrolled="
            templateContained(student) || enrolledCourseStudent(course, student)
          "
        />
        <app-manager-hourly-button
          v-else
          :course="course"
          :day="day"
          :student="student"
          :calendar="calendar"
          :enrolled="enrolled"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { isEmpty, isUndefined } from 'lodash/core'
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_ENROLLED_COURSES } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { calendarMethods } from '@/components/mixins/calendar'
import ManagerHourlyButton from './ManagerHourlyButton'
import HourlyButton from './HourlyButton'
import ManagerStandardButton from './ManagerStandardButton'
import StandardButton from './StandardButton'
const moment = extendMoment(Moment)

// given a course this generates the enrollment buttons for signing up students if you are a manager logged in or adding to the cart it is a parent/staff member
export default {
  name: 'HourlyButtons',
  mixins: [courseMethods, calendarMethods],
  components: {
    appManagerHourlyButton: ManagerHourlyButton,
    appHourlyButton: HourlyButton,
    appManagerStandardButton: ManagerStandardButton,
    appStandardButton: StandardButton
  },
  props: {
    course: { type: Object, required: true },
    day: { type: Object, required: false },
    bulk: {
      type: Boolean,
      required: false,
      default: false
    },
    calendar: {
      type: Object,
      required: false
    },
    signupClosed: {
      type: String,
      requred: false,
      default: ''
    },
    closedReason: {
      type: String,
      required: false,
      default: 'Signup is closed'
    },
    students: {
      type: [Object, Array],
      default: function () {
        return {}
      }
    },
    enrolled: {
      type: Object,
      required: false
    },
    overlap_courses: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      studentSelections: []
    }
  },
  created () {
    if (!this.bulk) {
      const students = this.studentsWhoCanEnroll
      this.studentSelections = students.map(function (student) {
        const s = {
          name: student.name,
          first: student.first,
          id: student.id,
          selectedStart: null,
          selectedEnd: null
        }
        return s
      })
    } else {
      this.studentSelections = [
        {
          name: 'All',
          first: '',
          id: 'bulk',
          selectedStart: null,
          selectedEnd: null
        }
      ]
    }
  },
  mounted () {},
  methods: {
    ...mapActions({
      updateEnrolledCourses: UPDATE_ENROLLED_COURSES
    }),
    addCourseToCartForStudent (student, start, end, event) {
      if (!isEmpty(event)) {
        event.target.disabled = true
      }
      if (isEmpty(this.course.session_id)) {
        if (!isEmpty(this.course.site_calendar_id)) {
          this.addDayToCart(student, this.day.date, this.course, start, end)
            .then(data => {
              if (!isEmpty(event)) {
                event.target.disabled = false
              }
              if (!isEmpty(data.students)) {
                const students = data.students
                for (const studentId in students) {
                  const student = students[studentId]
                  var message = ''
                  if (!isEmpty(student.courses)) {
                    const courses = student.courses
                    var courseNames = []
                    for (const courseId in courses) {
                      const course = courses[courseId]
                      const courseDay = moment(course.day).format('M/D')
                      courseNames.push(course.name + ' ' + courseDay)
                    }
                    message = courseNames.join(',')
                  }
                  this.$notify({
                    group: 'enrollment',
                    type: 'success',
                    title: student.first + ' added to cart',
                    text: message,
                    duration: 2000,
                    speed: 1000
                  })
                }
              }
            })
            .catch(() => {
              if (!isEmpty(event)) {
                event.target.disabled = false
              }
              this.$notify({
                group: 'enrollment',
                type: 'error',
                title: student.name + ' problem',
                text: student.name + ' problem adding student to cart for day.',
                duration: 2000,
                speed: 1000
              })
            })
        }
      } else {
        this.addTimeToCart(student, start, end, event)
      }
    },
    // sets the start time and can enroll/add to cart the student
    selectedStart (student) {
      return this.findStudent(student).startSelection
    },
    findStudent (student) {
      const students = this.studentSelections
      const found = students.find(function (find) {
        return student.id === find.id
      })
      return found
    },
    setSelectedStart (selected) {
      const time = selected.time
      const student = selected.student
      const found = this.findStudent(student)
      found.selectedStart = time
      this.shouldAddTimesToCart(student)
    },
    setSelectedEnd (selected) {
      const time = selected.time
      const student = selected.student
      const found = this.findStudent(student)
      found.selectedEnd = time
      this.shouldAddTimesToCart(student)
    },
    shouldAddTimesToCart (student) {
      const course = this.course
      const found = this.findStudent(student)
      if (course.allow_start && course.allow_end) {
        if (!isEmpty(found.selectedStart) && !isEmpty(found.selectedEnd)) {
          this.addCourseToCartForStudent(
            student,
            found.selectedStart,
            found.selectedEnd,
            event
          )
        } else {
          let message = isEmpty(found.selectedStart)
            ? 'Select Pickup Time for '
            : 'Select Drop Off Time for '
          message = message + student.name
          this.$notify({
            group: 'enrollment',
            type: 'warning',
            title: this.course.name,
            text: message,
            duration: 2000,
            speed: 1000
          })
        }
      } else if (course.allow_start || course.allow_end) {
        const found = this.findStudent(student)
        this.addCourseToCartForStudent(
          student,
          found.selectedStart,
          found.selectedEnd
        )
      }
    },
    isStudentEnrolled (student) {
      const course = this.course
      if (
        !isUndefined(this.day) &&
        !isEmpty(this.day) &&
        !isUndefined(this.day.course) &&
        !isEmpty(this.day.course) &&
        !isUndefined(this.day.course[course.id]) &&
        !isEmpty(this.day.course[course.id])
      ) {
        const dayCourse = this.day.course[course.id]
        if (!isUndefined(dayCourse.students) && !isEmpty(dayCourse.students)) {
          const students = dayCourse.students
          if (
            !isUndefined(students[student.id]) &&
            !isEmpty(students[student.id])
          ) {
            return true
          }
        }
      } else if (
        typeof course.students !== 'undefined' &&
        course.students.length > 0
      ) {
        const inCourse = course.students.find(function (checkStudent) {
          return checkStudent.id === student.id
        })
        if (typeof inCourse !== 'undefined') {
          return true
        }
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      program: 'program',
      registration: 'registration',
      session: 'selectedSession',
      enrolled_courses: 'enrolledCourses',
      member: 'registrationMember',
      isManager: 'gteManager',
      blockEnrollmentWithUnpaid: 'blockEnrollmentWithUnpaid'
    }),
    specialDayName () {
      if (!isEmpty(this.day) && !isEmpty(this.day.special_day)) {
        if (!isEmpty(this.day.special_day.name)) {
          return this.day.special_day.name
        } else {
          return 'Regular'
        }
      }
      return ''
    },
    dayOfWeek: function () {
      const dayOfWeek = moment(this.day.date).day()
      return dayOfWeek
    },
    studentsWhoCanEnroll: function () {
      return this.studentsWhoCanEnrollInCourse(this.course, this.students)
    }
  }
}
</script>

<style scoped>
.select-time >>> .multiselect__placeholder {
  color: black;
}
.cart-time >>> .multiselect__tags {
  color: var(--red);

  background-color: var(--warning);
}
</style>
