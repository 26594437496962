import { API, MANAGER_API } from '@/utilities/http-common'

const getCourses = (sessionId, programSlug) => {
  return MANAGER_API.get(`/programs/${programSlug}/courses`, {
    params: {
      session_id: sessionId
    }
  }).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.courses
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const getShallowCourses = ids => {
  return API.get('/courses/shallow', {
    params: {
      ids: ids
    }
  }).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.courses
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const getCourse = id => {
  return API.get(`/courses/${id}`).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.course
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const addStudentCourseToWaitlist = (student, course, memberId, registrationId, programSlug) => {
  const waitlist = {
    course_id: course.id,
    type: course.waitlist,
    student_id: student.id,
    registration_id: registrationId,
    member_id: memberId
  }
  return API.put(`/programs/${programSlug}/waitlists/add`, waitlist)
}

const removeStudentCourseFromWaitlist = (waitlistRecord, programSlug) => {
  const params = {
    id: waitlistRecord.id,
    program_slug: programSlug
  }

  return API.delete(`/programs/${programSlug}/waitlists/delete/${waitlistRecord.id}`, params)
}

const removeStudentCourseFromCart = (student, course, registrationId, programSlug) => {
  if (typeof event !== 'undefined') {
    event.target.disabled = true
  }
  const enrolledCourse = {
    course_id: course.id,
    student_id: student.id,
    session_id: course.session_id,
    registration_id: registrationId
  }
  const url = 'programs/' + programSlug + '/enrolled_courses/delete'
  return API.delete(url, { params: enrolledCourse })
}

export {
  getCourses, getShallowCourses, getCourse,
  addStudentCourseToWaitlist,
  removeStudentCourseFromWaitlist,
  removeStudentCourseFromCart
}
