export const SIZE_WARNING = 10
export const SIZE_ALERT = 5

export const CART_EXPIRE_WARNING = 5
export const CART_EXPIRE_DANGER = 3

export const INITIAL_STATE_SHOW_UNPAID = 'SHOW_UNPAID'
export const INITIAL_STATE_SHOW_ON_BOARD = 'SHOW_ON_BOARD'
export const INITIAL_STATE_FORCE_PAY_UNPAID = 'FORCE_PAY_UNPAID'
export const INITIAL_STATE_PAYMENT_PROFILES = 'PAYMENT_PROFILES'
export const INITIAL_STATE_HAS_UNPAID = 'HAS_UNPAID'
export const INITIAL_STATE_BLOCK_ENROLMENTS_DUE_UNPAID =
  'BLOCK_ENROLMENTS_DUE_UNPAID'
